import { useNavigate, Link, useOutletContext } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { Fragment, useEffect, useState } from "react";
import { api, common, config, types } from "helpers";
import VerifyModal from "elements/modalNotVerified";
import Accordion from "react-bootstrap/Accordion";

function List() {
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const [isModal, setIsModal] = useState(null);
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    if (authInfo?.role !== "student") {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            setUser(res.data);
          }
        }
      );
    }
  };

  const onAction = (data) => {
    if (data.type === "Add") {
      navigate(`/agent/student/add`);
    } else if (data.type === "Edit") {
      if(authInfo.role == "sub_agent" && (user && user?.permissions?.length > 0)){
        if(user?.permissions[0]?.stuView == "stuall" && user?.permissions[0]?.stuUpdate == "stumy"){
          if(data?.item?.subAgentId && data?.item?.subAgentId === user?._id){
            navigate(`/agent/student/edit/${data.item._id}/personal_address/2`);
          } else {
            common.notify("E", "Edit Access Not Allowed");
          }
        } else{
          navigate(`/agent/student/edit/${data.item._id}/personal_address/2`);
        }
      } else {
        navigate(`/agent/student/edit/${data.item._id}/personal_address/2`);
      }
    } else if (data.type === "View") {
      if (data.module == "agentStudent") {
        navigate(`/agent/student/view/${data.item._id}`);
      } else {
        navigate(`/agent/promoters/student/info/${data.item._id}`);
      }
    } else if (data.type === "Apply") {
      navigate(`/course/finder/p/${data.item._id}`);
    }
  };

  const userVerify = (val) => {
    if (authInfo?.role === "agent") {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            if (res?.data.isVerified) {
              navigate(`/agent/student/add`);
            } else {
              setIsModal(true);
            }
          }
        }
      );
    } else {
      navigate(`/agent/student/add`);
    }
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex justify-content-between align-items-center py-2">
        <h1 className="page-title">Students</h1>
        {/* <Link to="/agent/student/add"  className="text-dec-none"> */}
        <button
          onClick={() => userVerify()}
          className="btn-signin d-flex justify-content-between align-items-center"
        >
          <span className="ms-3">New Student</span>
          <img
            src={common.loadImg("plus-sign.svg")}
            className="me-3"
            alt="promotion-icn"
          ></img>
        </button>
        {/* </Link> */}
      </div>
      <div className="content-wrap">
      {(user?.isPromotor || user?.agent?.isPromotor) ?
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>My Students</Accordion.Header>
        <Accordion.Body>
      
        {/* <h1 className="page-title">My Student</h1> */}
        <AgTable
          module="agentStudent"
          filterData={{
            dateA: { key: "startDate", value: "", title: "Created Date From" },
            dateB: { key: "endDate", value: "", title: "Created Date To" },
          }}
          onAction={onAction}
        />
     
      </Accordion.Body>
      </Accordion.Item>
      {/* {(user?.isPromotor || user?.agent?.isPromotor) && */}
      <Accordion.Item eventKey="1">
        <Accordion.Header>Agent Students</Accordion.Header>
        <Accordion.Body>
          <AgTable
            module="coordinatorAgentStudent"
            filterData={{
              dateA: {
                key: "startDate",
                value: "",
                title: "Created Date From",
              },
              dateB: { key: "endDate", value: "", title: "Created Date To" },
            }}
            onAction={onAction}
          />
      </Accordion.Body>
      </Accordion.Item>
       {/* } */}
    </Accordion>
    :
    <AgTable
          module="agentStudent"
          filterData={{
            dateA: { key: "startDate", value: "", title: "Created Date From" },
            dateB: { key: "endDate", value: "", title: "Created Date To" },
          }}
          onAction={onAction}
        />
}
    </div>

      {isModal && <VerifyModal onClose={() => setIsModal(null)} />}
    </Fragment>
  );
}

export default List;
