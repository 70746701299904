import { Container, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
// includes
import { common } from "helpers";

const FrontHeader = () => {
  const location = useLocation();
  const menuClick = () => {
    const toggle = document.querySelector("[aria-label = 'Toggle navigation']");
    toggle.click();
  };
  return (
    <header>
      <Navbar
        className="header-nav-wrap custom-header-nav-wrap fixed-header"
        bg="light"
        expand="lg"
        sticky="top"
      >
        <Container fluid className="p-0">
          <Navbar.Brand>
            <Link to="/" className="text-dec-none">
              {/* <img src={common.cloudImg("media/logo.svg")} /> */}
              <img
                src={common.loadImg("pickauni-logo-white.png")}
                alt="logo"
                className="header-logo-img"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            {/* <div className="head-user-info">
              <Link to="/login" className="et-header-link me-3">
                Welcome, Sign in
              </Link>
              <img src={common.loadImg("user-icon.svg")} alt="userimage" />
            </div> */}
            <div
              className="front-layout-menu-wrap"
              id="front-layout-menu-wrap"
              onClick={menuClick}
            >
              {location?.pathname?.match(/register/i) ||
              location?.pathname === "/" ||
              location?.pathname === "/password/forgot" ? (
                <div>
                  <Link to="/login" className="et-header-link me-3">
                    Sign In{" "}
                    <img
                      src={common.loadImg("msg-icn.svg")}
                      className="ps-2"
                      alt="userimage"
                    />
                  </Link>
                </div>
              ) : null}

              {location?.pathname === "/" ? (
                <div className="header-divider"></div>
              ) : null}
              {location?.pathname?.match(/login/i) ? (
                <div>
                  <Link to="/" className="et-header-link me-3">
                    Register{" "}
                    <img
                      src={common.loadImg("msg-icn.svg")}
                      className="ps-2"
                      alt="userimage"
                    />
                  </Link>
                </div>
              ) : null}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default FrontHeader;
