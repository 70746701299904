import React, { useState } from "react";
// includes
import { common } from "helpers";
import PaymentForm from "includes/paymentForm";
import { Link } from "react-router-dom";

function Six(props) {
  // state
  const [tempData] = useState(common.tempDataGet());
  //render
  return (
    <div className="row">
      <div className="col-12 agent-signup-wrap py-5">
        <div className="col-md-10 mx-auto">
          <div className="right-content-width remove-border">
            <p className="ref-heading1 d-flex justify-content-between align-items-center">
              <span>Payment</span>
              <Link to="" onClick={props.onBack} className="ref-heading1-link">
                Change Plan
              </Link>
            </p>
            <PaymentForm
              auth={false}
              category="subscription"
              taxKey="subscription"
              promo={false}
              id={tempData.planId}
              onSuccess={props.onSuccess}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Six;
