import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

function Register() {
  const [run, setRun] = useState(true);
  return (
    <Fragment>
      <div className="container-fluid content-wrap-pt">
        <div className="row">
          <div className="col-12 et-page-wrap py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-5 ms-auto mb-3">
                  <div className="box-header">
                    <p className="title text-center">Students</p>
                  </div>
                  <div className="box-body text-center">
                    <div className="reg-body-wrap">
                      <div className="register-pg-box">
                        <h3 className="register-pg-heading1">
                          Don’t know which university to choose?
                        </h3>
                        <p className="register-pg-p">
                          Finding the perfect university is as easy as 1, 2, 3
                          if you know your options
                        </p>
                      </div>
                      <div className="register-pg-box">
                        <h3 className="register-pg-heading1">
                          Offer Letter Taking Too Long ?
                        </h3>
                        <p className="register-pg-p">
                          Take your pick and get your University offer in just
                          10 days
                        </p>
                      </div>
                      <div className="register-pg-box">
                        <h3 className="register-pg-heading1">
                          Cashback and Savings on Course Fee
                        </h3>
                        <p className="register-pg-p">
                          Get 10% Cash back on course fee and $4000 of Savings
                          on Purchases
                        </p>
                      </div>
                    </div>
                    <div className="register-pg-box">
                      <Link to="/register/student/direct">
                        <button className="btn-default btn-reg">
                          Register As Student
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="shadow-div"></div>
                  <div className="greenshow-div"></div>
                </div>
                <div className="col-md-5 me-auto mb-3">
                  <div className="box-header">
                    <p className="title text-center">Partners</p>
                  </div>
                  <div className="box-body text-center">
                    <div className="reg-body-wrap">
                      <div className="register-pg-box">
                        <h3 className="register-pg-heading1">
                          Apply & Get Verified
                        </h3>
                        <p className="register-pg-p">
                          Apply online and get verified to start your student
                          application management journey
                        </p>
                      </div>
                      <div className="register-pg-box">
                        <h3 className="register-pg-heading1">
                          Manage Students
                        </h3>
                        <p className="register-pg-p">
                          You can see the entire progress of students. Enroll
                          one student in every 3 applications
                        </p>
                      </div>
                      <div className="register-pg-box">
                        <h3 className="register-pg-heading1">
                          Get Paid On Time
                        </h3>
                        <p className="register-pg-p">
                          Get your commissions on time and save ton of money and
                          time
                        </p>
                      </div>
                    </div>
                    <div className="register-pg-box">
                      <Link to="/register/agent">
                        <button className="btn-default btn-reg">
                          Register As Partner
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="shadow-div"></div>
                  <div className="greenshow-div"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default Register;
