import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { config } from "../helpers/config";

const firebaseApp = initializeApp(config.firebaseConfig);
const messaging = getMessaging(firebaseApp);

console.log({ firebaseApp });
const FireBase = {
  getDeviceToken: async (setTokenFound) => {
    return getToken(messaging, {
      vapidKey:
        "BDzIf_0KWtOzSB2CGaFPzc5hGIAQu3fjmbXRyrH9NdFWAjMxxfN2PayGDbDo9st0WcCMaG3NWSegqZLsEKRNM8I",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          setTokenFound(currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(null);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  },
  msgListener: () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        console.log({ payload });
        resolve(payload);
      });
    }),
};

export default FireBase;
